import i18next from 'i18next';

import type { ActivityFeedItemType } from '../../types/ActivityFeedTypes';

// TODO: Localize this file

export const timeAgo = (date: string | Date) => {
  const currentDate = new Date().getTime();
  const targetDate = new Date(date).getTime();
  const diff = (currentDate - targetDate) / 1000; // time difference in seconds
  let final = '';

  // matches moment's rounding pattern https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/
  if (diff < 89) {
    final = '1m';
  } else if (diff > 89 && diff < 2640) {
    // get diff in minutes but round up to follow Moment
    final = `${Math.round(diff / 60)}m`;
  } else if (diff >= 2640 && diff < 5340) {
    final = '1h';
  } else if (diff >= 5340 && diff < 75600) {
    // 3600 seconds in an hour
    final = `${Math.round(diff / 3600)}h`;
  } else if (diff >= 75600 && diff < 126000) {
    final = '1d';
  } else if (diff >= 126000 && diff < 2160000) {
    // 86400 seconds in a day
    final = `${Math.round(diff / 86400)}d`;
  } else if (diff >= 2160000 && diff < 3888000) {
    final = '1mo';
  } else if (diff >= 3888000 && diff < 27561600) {
    final = `${Math.round(diff / 2628000)}mo`;
  } else if (diff >= 27561600 && diff < 47260800) {
    final = '1y';
  } else final = `${Math.round(diff / 31536000)}y`;

  return final;
};

const isSameDate = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const isToday = (date: Date) => {
  const today = new Date();
  const inputDate = new Date(date);
  return isSameDate(today, inputDate);
};

export const isYesterday = (date: Date) => {
  const inputDate = new Date(date);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return isSameDate(yesterday, inputDate);
};

export const isThisWeek = (date: Date) => {
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 6);
  lastWeek.setHours(0);
  lastWeek.setMinutes(0);
  lastWeek.setSeconds(0);
  lastWeek.setMilliseconds(0);
  const lastWeekTime = lastWeek.getTime();
  const inputDate = new Date(date).getTime();
  const now = new Date().getTime();
  return inputDate > lastWeekTime && inputDate < now;
};

// This week but not today or yesterday
export const isThisWeekNotTodayYesterday = (date: Date) => !isToday(date) && !isYesterday(date) && isThisWeek(date);

// Check if date is after this week
export const isAfterThisWeek = (date: Date) => !isThisWeek(date);

// Filter data using date helpers and time stamp
export const filterByDate = (arr: ActivityFeedItemType[], helper: (data: Date) => boolean) => {
  return arr.filter(el => {
    const {
      attributes: { created_at },
    } = el;
    const createdAtDate = new Date(created_at);
    return helper(createdAtDate);
  });
};

export const getDiffFromNow = (date: string) => {
  const now = new Date().getTime(); // Right now in msec
  const then = new Date(date).getTime(); // Date in msec
  return (now - then) / (3600 * 1000); // Returning diff in hours
};

export const getFullDate = (date: string) => {
  return new Date(date).toLocaleString();
};

export const getDateAndTime = (date: string) => {
  return new Date(date).toLocaleString(i18next.language, {
    month: 'numeric',
    day: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  });
};
